import React from "react";
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { FaCalendarAlt, FaUsers } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { RiUserSearchLine } from "react-icons/ri";

export default function BottomNav({ color, color1, color2, color3 }) {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white">
      <div className="flex justify-between items-center mx-4">
        <Link to="/dashboard">
          <div className={color ? color + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <AiFillHome className="text-4xl" />
            <p>Home</p>
          </div>
        </Link>
        <Link to="/patient-record">
          <div className={color1 ? color1 + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <RiUserSearchLine className="text-4xl" />
            <p>Patients</p>
          </div>
        </Link>
        <Link to="/recent-visits">
          <div className={color2 ? color2 + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <FaUsers className="text-4xl" />
            <p>Visits</p>
          </div>
        </Link>
        <Link to="/payment-history">
          <div className={color3 ? color3 + ' ' + "font-bold flex flex-col justify-center items-center" : "text-ash100 font-bold flex flex-col justify-center items-center"}>
            <MdPayment className="text-4xl" />
            <p>Payment</p>
          </div>
        </Link>
      </div>
    </div>
  );
}
