import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { get_patient } from '../../redux/actions/patient'
import BottomNav from '../BottomNav'
import Button from '../Button/Button'
import Loading from '../Loading'
import TopBar from '../TopBar'
import { getNotes } from '../../redux/actions/notes'
import { getInvInvestigation } from '../../redux/actions/DInvestigation'

export default function Investigation() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, success, error, data } = useSelector(state => state.getInvInvestigation);
    const [state, setState] = useState([]);
    const [patient, setPatient] = useState();
    const [investigation, setInvestigation] = useState([]);
    const [reRender, setRerender] = useState(false);
    const location = useLocation().state;
    
    

    const setN = () => {
        let nt = state?.filter((obj, index, self) =>
            // index === self.findIndex((t) => (
            obj.session_id === location
            // ))
        );
        setInvestigation(nt);
        setRerender(true);
        
    }

    useEffect(() => {
        setState(data?.investigation);
        setN();
        if (localStorage.getItem("userInfo") === null) {
            navigate("/login");
        } if (!success) {
            dispatch(getInvInvestigation(localStorage.getItem('patient_id')));
        }

        return () => {
            setPatient('');
          
        };
    }, [success, loading, reRender]);

    return (
        <div className="h-full">
            <TopBar title={'Diagnostic Investigation'} url={-1} />
            <div className="mt-6 flex justify-center items-center">
                <p className='font-bold text-[22px]' >{patient?.system_id}</p>
            </div>
            <div className="mt-4 flex justify-between items-center mx-2">

                <button className="w-full py-2 border-2 border-primary100 rounded-md text-primary100 text-[18px] mx-5 mb-[2rem]" >
                    {investigation?.length > 0 ? investigation?.[0].patient_firstname + ' ' + investigation?.[0].patient_lastname : 'Diagnostic Investigation'}
                </button>
            </div>
            <div className='pb-[5rem]' >
            {
                loading ? <Loading /> :
                    investigation?.length > 0 ? state?.map((item) =>
                        <div className='mx-4'>
                            <p className='text-[18px]' >Doctors Name: <b>{item?.doctors_firstname + ' ' + item?.doctors_lastname}</b> </p>
                            <p className='text-[18px]' >Notes: <b>{item?.notes}</b> </p>
                            <p className='text-[18px]' >Item: <b>{item?.item_name}</b> </p>
                            <p className='text-[18px]' >Session: <b>{item?.session_id}</b> </p>
                            <p className='text-[18px]' >Test ID: <b>{item?.test_id}</b></p>
                            {/* <label htmlFor="message" class="block m-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">{new Date(item.created).toLocaleDateString()} |  <span className='ms-4' >Date</span> </label>
                            <div dangerouslySetInnerHTML={
                                function createMarkup() {
                                    return { __html: item.notes };
                                }()
                            } /> */}
                            <hr style={{height: 5, backgroundColor: '#087F4C', marginTop: 5 }} />
                        </div>
                    ) : <div className='mx-8 font-medium'>
                        <p>No records found</p>
                    </div>

            }
            </div>

            <BottomNav color1={'text-primary100'} />
        </div>
    )
}
