import { GET_DIAGNOSIS_FAIL, GET_DIAGNOSIS_REQUEST, GET_DIAGNOSIS_SUCCESS } from "../constants/Diagnosis";

const initialState = {
    loading: false,
    success: false,
    error: null,
    data: null
}

const Diagnosis = (state = initialState, action) => {
    switch (action.type) {
        case GET_DIAGNOSIS_REQUEST:
            return { ...state, loading: true };
        case GET_DIAGNOSIS_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case GET_DIAGNOSIS_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}


export { Diagnosis }
