import { useEffect } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
createTheme('light', {
    text: {
        primary: '#268bd2',
        secondary: '#2aa198',
    },
    background: {
        default: '#002b36',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#073642',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

const columns = [
    {
        name: 'Payment Date',
        selector: row => new Date(row.created).toLocaleDateString(),
    },
    {
        name: 'Patient',
        selector: row => row.firstname + ' ' + row.lastname,
    },
    {
        name: 'Patient No',
        selector: row => row.mobile,
    },
    {
        name: 'Amount',
        selector: row => row.amount,
    },
    {
        name: 'Payment Method',
        selector: row => row.pay_option,
    },
    {
        name: 'User Name',
        selector: row => row.payment_by,
    },
];

// const data = [
//     {
//         id: 1,
//         title: 'Beetlejuice',
//         year: '1988',
//         dd: 'Test'
//     },
//     {
//         id: 2,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
//     {
//         id: 2,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
//     {
//         id: 2,
//         title: 'Ghostbusters',
//         year: '1984',
//     },
// ]

export default function DTable({data}) {
    useEffect(() => {
        
    }, []);
    return (
        // <DataTable
        //     columns={columns}
        //     data={data}
        //     theme="solarized"
        // />
        <DataTable title="Payment History" columns={columns} data={data} pagination />
    );
};