import { GET_INVESTIGATION_FAIL, GET_INVESTIGATION_REQUEST, GET_INVESTIGATION_SUCCESS } from "../constants/DIvestigation";

const initialState = {
    loading: false,
    success: false,
    error: null,
    data: null
}

const getInvInvestigation = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVESTIGATION_REQUEST:
            return { ...state, loading: true };
        case GET_INVESTIGATION_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case GET_INVESTIGATION_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}


export { getInvInvestigation }
