import React from "react";
import pana from "../assets/pana.png";
import { Link } from "react-router-dom";
function Welcome() {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="h-screen w-full relative rounded-md shadow-xl justify-center flex flex-col items-center">
        <div className="flex justify-center items-center mt-5 mb-5">
          <img src={pana} width="100%" height="100%" />
        </div>
        <h1 className="flex justify-center items-center md:text-[40px] text-[24px] font-extrabold ">
          Welcome to Misas mini Report
        </h1>
        <p className="flex justify-center items-center text-[16px] md:text-[16px] px-[20px] mb-3 text-center">
          Stay updated with recent patient reports, visits, consultations, Payments and more.
        </p>
          {" "}
        <div className=" w-full flex justify-center items-center">
            <button className=" w-full py-2 bg-primary100 rounded-md text-white text-[18px] mx-5">
        <Link to="/login">
              Login
        </Link>
            </button>
          </div>
        {/* <Link to="/register">
          <div className="flex justify-center items-center">
            <button className=" w-full py-2 bg-white rounded-md text-primary100 text-[18px] mx-5 outline outline-primary100 outline-1 mt-3">
              Change Password
            </button>
          </div>
        </Link> */}
      </div>
    </div>
  );
}

export default Welcome;
