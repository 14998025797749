import React, { useEffect, useState } from "react";
import PatientCard from "../../components/Patient/PatientCard";
import Button from "../../components/Button/Button";
import TopBar from "../../components/TopBar";
import doctorPics from "../../assets/clinic.jpg";
import { Link } from "react-router-dom";
import BottomNav from "../../components/BottomNav";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";
import { search_patient_request } from "../../redux/actions/patient";
import Loading from "../../components/Loading";
import { check_token } from "../../utils";

export default function Patient() {
    const store = useSelector(state => state?.search_patient_request);
    // 
    const dispatch = useDispatch();
    const {data,loading, success} = store;
    const [patients, setPatients] = useState([]);
    const [pat, setPat] = useState([]);
    const [state, setState] = useState('');

    
    
    
    const handleChange = (e) => {
        setState(e.target.value);
        if (state.length >= 2) {
            dispatch(search_patient_request(state));
            setPatients(store.data?.patients);
        }
        // else {
        //     setPatients(pat)
        // }
    } 
    useEffect(() => {
        check_token();
        if (!success) {
            dispatch(search_patient_request(state))
        }
    }, [])
    
    useEffect(() => {
        if (pat == undefined || pat.length == 0) {
            setPat(data?.patients)
        }
        setPatients(data?.patients)
    }, [success,loading])

    return (
        <div className="h-full">
            <TopBar title={"Patients Record"} url={'/dashboard'} />
            <div className="mt-6 flex justify-center items-center w-6/6">
                <label class="block">
                    <span class="sr-only">Search</span>
                    <span class="absolute inset-y-0 left-0 flex items-center pl-2 ">
                        <svg class="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"></svg>
                    </span>
                    <input class="placeholder:italic placeholder:text-slate-400 block bg-white w-6/6 border border-slate-300 rounded-md py-2 px-8 shadow-sm focus:outline-none focus:border-primary100 focus:ring-primary100 focus:ring-1 sm:text-sm" placeholder="Search for a patient..." type="text" name="search" value={state} onChange={handleChange} />
                </label>
            </div>
                <hr className="bg-primary100" height="5px"/>
            <div className="pb-[5rem]">
              
                {
                    loading ? <Loading/>:
                        patients?.length > 0 ? patients?.map((item) => {
                            return (
                                <PatientCard
                                    pics={'https://misas.com.ng/assets/images/logos/misaslogo.png'}
                                    name={ item.firstname + ' ' + item?.lastname }
                                    title={item?.title}
                                    gender={item?.gender}
                                    phone={item?.mobile}
                                    id={item.id}
                                />
                            )
                        })
                        : <div className="flex items-center justify-center flex-col p-4 mt-16">
                            <p className="text-slate-400 font-bold" >Use the search box to search for a patient record.</p>
                            <div className="mt-3">
                                <FiSearch className="text-8xl text-primary100" />
                            </div>
                        </div>
                }
                            
                </div>
                        
           
                  
            <BottomNav color1={'text-primary100'} />
        </div>
    );
}
