import React from 'react'
import PTable from './PTable';
import TopBar from '../TopBar'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { Prescriptions } from '../../redux/actions/Prescriptions';
import Loading from '../Loading';


export default function PrescriptionScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, success, error, data } = useSelector(state => state.Prescriptions);
    const [state, setState] = useState([]);
    

    useEffect(() => {
        dispatch(Prescriptions(localStorage.getItem('patient_id')));
    //   setTimeout(() => {
          setState(data?.prescriptions)
    //   }, 500);
    }, [success]);

  return (
      <div className="h-screen">
          <TopBar title={"Prescriptions"} url={-1} />
          <div className='mt-8'>
              {
                  loading ? <Loading /> : state?.length < 1 ? <div className="flex justify-center"><p className='self-center' >There are no records to display</p></div> : <PTable title={state?.length > 0 ? state?.[0].firstname + ' ' + state?.[0].lastname : 'Prescriptions'} data={state} />
              }
              
          </div>
      </div>
  )
}
