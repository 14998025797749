import { GET_PAYMENT_REQUEST, GET_PAYMENT_SUCCESS, GET_PAYMENT_FAIL, GET_RECENT_PAYMENT_FAIL, GET_RECENT_PAYMENT_SUCCESS, GET_RECENT_PAYMENT_REQUEST } from "../constants/payment";
import axios from "axios";
import { baseUrl } from "./baseUrl";

export const getPayment = (newValue) => async (dispatch) => {
    try {
        dispatch({
            type: GET_PAYMENT_REQUEST,
        });
       
        const config = {
            headers: {
                "Content-Type": "Application/json",
                "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('md_btn_mi_sas'))),
            },
        };
        const { data } = await axios.get(
            `${baseUrl}/payment_summary?startDate=${newValue.startDate}&endDate=${newValue.endDate}`,
            config
        );

        dispatch({
            type: GET_PAYMENT_SUCCESS,
            payload: data,
        });
       
    } catch (err) {
        let message =
            err.response && err.response.data.errors
                ? err.response.data.errors
                : err.message;
        dispatch({
            type: GET_PAYMENT_FAIL,
            payload: message,
        });

    }
};


