import { useEffect } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { AiFillEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
createTheme('light', {
    text: {
        primary: '#268bd2',
        secondary: '#2aa198',
    },
    background: {
        default: '#002b36',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#073642',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

const columns = [
    {
        name: 'Session',
        selector: row => row.session_id,
        wrap: true,
    },
    {
        name: 'Medicine',
        selector: row => row.medicine_name,
        wrap: true,
    },
    {
        name: 'Dosage',
        selector: row => row.dosage,
        wrap: true
        }

];


export default function PTable({ data, title }) {
    useEffect(() => {

    }, []);
    return (

        <DataTable title={title} columns={columns} data={data} pagination />
    );
};