import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { get_patient } from '../../redux/actions/patient'
import BottomNav from '../BottomNav'
import Button from '../Button/Button'
import Loading from '../Loading'
import TopBar from '../TopBar'
import { getNotes } from '../../redux/actions/notes'
import { check_token } from '../../utils'

export default function Notes() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, success, error, data } = useSelector(state => state.getNotes);
    const [state, setState] = useState();
    const [patient, setPatient] = useState();
    const [notes, setNotes] = useState();
    const [pageNotes, setPageNotes] = useState([]);
    const [reRender, setRerender] = useState(false);
    const location = useLocation().state;
    
    
    // 
    const setN = () => {
        let nt = notes?.filter((obj, index, self) =>
            // index === self.findIndex((t) => (
            obj.session_id === location
            // ))
        );
        setPageNotes(nt);
        
        setRerender(true);
    }

    useEffect(() => {
        check_token();
        setNotes(data?.doctors_note);
        setN();
        if (!success) {
            dispatch(getNotes(localStorage.getItem('patient_id')));
        }
         
        return () => {
            setPatient('');
            setNotes('')
            setPageNotes([]);
        };
    }, [success, loading, reRender]);

    return (
        <div className="h-full">
            <TopBar title={pageNotes?.length >0 ? pageNotes[0]?.name[0]?.firstname + ' ' + pageNotes[0]?.name[0]?.lastname: 'Notes' } url={-1} />
            <div className="mt-6 flex justify-center items-center">
                <p className='font-bold text-[22px]' >{patient?.system_id}</p>
            </div>
            <div className="mt-4 flex justify-between items-center mx-2">
               
                <button className="w-full py-2 border-2 border-primary100 rounded-md text-primary100 text-[18px] mx-5 mb-[2rem]" >
                    Doctor’s Note
                </button>
            </div>
            {
                loading ? <Loading /> :
                    pageNotes?.length > 0 ? pageNotes?.map((item) => 
                        <div className='mx-4'>
                            <label htmlFor="message" class="block m-2 text-sm font-medium text-gray-900 dark:text-gray-400 ">{new Date(item.created).toLocaleDateString()} |  <span className='ms-4' >{item.doctors_name[0].firstname} {item.doctors_name[0].lastname}</span> </label>
                            <div dangerouslySetInnerHTML={
                                function createMarkup() {
                                    return { __html: item.notes };
                                }()
                            } />
                            <hr style={{ color: 'primary100', height: 5, backgroundColor: '#087F4C', marginTop: 5  }} />
                    </div>    
                    ) : <div className='mx-8 font-medium'>
                            <p>No records found</p>
                    </div>
                
            }


            <BottomNav color1={'text-primary100'} />
        </div>
    )
}
