import axios from "axios";
import logo from "./assets/logo.png";
import { baseUrl } from "./redux/actions/baseUrl";

function pushNotification(body) {
    Notification.requestPermission((result) => {
        if (result === "granted") {
            const notification = navigator.serviceWorker.ready.then((registration) => {
                registration.showNotification("Misas", {
                    body: body,
                    icon: logo,
                    vibrate: [200, 100, 200, 100, 200, 100, 200],
                    tag: "vibration-sample",
                });

                notification.onclick = () => {
                    window.location.href = "/notification";
                };

            });
        }
    });
}

async function check_token() {
    if (localStorage.getItem('md_btn_mi_sas') == null) {
        window.location.href = "/login";
        return false;
    }

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('md_btn_mi_sas'))),
        },
    };

    try {
        const response = await axios.get(`${baseUrl}/check_token`, config);
        const { valid } = response.data;

        if (!valid) {
            window.location.href = "/login";
            localStorage.clear();
            return false;
        }

        return true;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = "/login";
            localStorage.clear();
            return false;
        }
        console.error("An error occurred while checking the token:", error);
        return false;
    }
}


export { pushNotification, check_token };