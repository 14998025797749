import { GET_PAYMENT_FAIL, GET_PAYMENT_SUCCESS, GET_PAYMENT_REQUEST, GET_RECENT_PAYMENT_REQUEST, GET_RECENT_PAYMENT_SUCCESS, GET_RECENT_PAYMENT_FAIL } from "../constants/payment";


const initialState = {
    loading: false,
    success: false,
    error: null,
    data: null
}

const getPaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYMENT_REQUEST:
            return { ...state, loading: true };
        case GET_PAYMENT_SUCCESS:
            return { ...state, loading: false, success: true, data: action.payload };
        case GET_PAYMENT_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}


export { getPaymentReducer }
