import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_USER_RESET,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER_RESET,
  LOGOUT_USER,
  GET_OTP_REQUEST,
  GET_OTP_SUCCESS,
  GET_OTP_FAIL,
  GET_OTP_RESET,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_RESET
} from "../constants/userConstants.js";

// const initialState = {
//     user:{}
// }
const createUserReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
      return { loading: true };
    case CREATE_USER_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case CREATE_USER_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_USER_RESET:
      return { data: action.payload };
    default:
      return state;
  }
};

const getOTpReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_OTP_REQUEST:
      return { loading: true };
    case GET_OTP_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case GET_OTP_FAIL:
      return { loading: false, error: action.payload };
    case GET_OTP_RESET:
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

const verifyOtpReducer = (state = {}, action) => {
  switch (action.type) {
    case VERIFY_OTP:
      return { loading: true };
    case VERIFY_OTP_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case VERIFY_OTP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;


const loginUserReducer = (
  state = { userInfo: userInfoFromLocalStorage },
  action
) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return { loading: true };
    case LOGIN_USER_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case LOGIN_USER_FAIL:
      return { loading: false, error: action.payload };
    case LOGIN_USER_RESET:
      return { loading: false, success: false, error: null };
    case LOGOUT_USER:
      return {
        userInfo: {},
      };
    default:
      return state;
  }
};

const logoutUserReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case LOGOUT_USER_REQUEST:
      return { loading: true };
    case LOGOUT_USER_SUCCESS:
      return { loading: false, success: true, data: action.payload };
    case LOGOUT_USER_FAIL:
      return { loading: false, error: action.payload };
    case LOGOUT_USER_RESET:
      return { loading: false, success: false, error: null };
    default:
      return state;
  }
};

export { createUserReducer, loginUserReducer, getOTpReducer, verifyOtpReducer, logoutUserReducer };
